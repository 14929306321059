import * as utils from "utils/utils";
import { ISocialCredentials } from "bob-group-ui-framework/dist/interfaces";

function getAccessToken() {
  return localStorage.getItem("access_token") ?? null;
}

function setAccessToken(token: any) {
  localStorage.setItem("access_token", token);
}

function logOut(store: any) {
  store.emitter.emit("logOut", false);
}

async function logIn(
  store: any,
  emailOrUserID: string,
  password: string,
  history: any,
  socialCredentials?: ISocialCredentials,
  twoFactorAuthPasscode?: string
) {
  // Try JWT login
  const args: any = {
    password,
    social_credentials: socialCredentials
  };

  if (typeof emailOrUserID === "string") args.email = emailOrUserID;
  else args.user_id = emailOrUserID;

  if (twoFactorAuthPasscode) {
    args.two_factor_auth_passcode = twoFactorAuthPasscode;
  }

  const jwtLoginResult = await utils.signedRequest(store, "/login", "POST", args);

  if (jwtLoginResult.ok && jwtLoginResult.data.access_token) {
    setAccessToken(jwtLoginResult.data.access_token);
    redirectAfterLogin(history);
    return {
      loginSuccess: true,
      user: jwtLoginResult,
      error: null
    };
  } else if (jwtLoginResult.code === 202) {
    // Decided to use 202 to indicate 2FA required
    return {
      request2FA: true
    };
  } else {
    const error = JSON.parse(jwtLoginResult.error.message).message;
    if (
      error.indexOf("password is incorrect") > -1 ||
      error.indexOf("user password is not set") > -1
    ) {
      return {
        loginSuccess: false,
        user: null,
        error: error
      };
    } else {
      return {
        loginSuccess: false,
        user: null,
        error: error
      };
    }
  }
}

function redirectAfterLogin(history: any) {
  if (history) {
    const urlParams = new URLSearchParams(window.location.search);
    const redirect = urlParams.get("redirect");
    if (redirect) {
      history.go(redirect);
    } else if (
      window.location.pathname.indexOf("/login") >= 0 ||
      window.location.pathname.indexOf("/register") >= 0
    ) {
      history.go("/");
    }
  }
}

async function resetPassword(store: any, userID?: any, email?: string) {
  const args: any = {};

  if (userID) {
    args.user_id = userID;
  }

  if (email) {
    args.email = email;
  }

  return await utils.signedRequest(store, "/users/reset-password", "POST", args);
}

async function updatePassword(store: any, password: string, userID?: any) {
  const args: any = { password };

  if (userID) {
    args.user_id = userID;
  }

  return await utils.signedRequest(store, "/users/update-password", "POST", args);
}

async function resendInvitation(store: any, email: any) {
  return await resetPassword(store, undefined, email);
}

async function verifyAccountFromEmailLink(
  store: any,
  verificationCode: string,
  email?: string,
  userID?: number,
  password?: string
) {
  const args: any = {
    verification_code: verificationCode
  };

  if (email) {
    args.email = email;
  }

  if (userID) {
    args.user_id = userID;
  }

  if (password) {
    args.password = password;
  }

  return await utils.signedRequest(store, "/login", "POST", args);
}

async function resetPasswordFromEmailLink(
  store: any,
  verificationCode: string,
  emailOrUsername: string,
  password?: string
) {
  const args: any = {
    verification_code: verificationCode,
    email: emailOrUsername
  };

  if (password) {
    args.password = password;
  }
  return await utils.signedRequest(store, "/login", "POST", args);
}

export {
  logIn,
  logOut,
  getAccessToken,
  resetPassword,
  updatePassword,
  resendInvitation,
  verifyAccountFromEmailLink,
  resetPasswordFromEmailLink
};
