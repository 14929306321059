import moment from "moment";
import config from "config";
import axios from "axios";

import { IUser } from "interfaces/user.interface";
import { IObjectKeys } from "interfaces/object.interface";
import { IResult } from "interfaces/result.interface";
import { IAccount } from "interfaces/account.interface";
import { InfoButton, Message, generalUtils, dateUtils } from "bob-group-ui-framework";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as authUtils from "utils/authUtils";
import * as constants from "constantsAndDefaults";

const { detect } = require("detect-browser");

// Used to check current app version
import packageJson from "../../package.json";
import { IBobBoxLocation } from "interfaces/bobBox/location.interface";
import { IStore } from "interfaces/store.interface";
import { isBobBoxUser } from "./roleUtils";
import { TailwindColors } from "bob-group-ui-framework/dist/types";

const activeRequests: any = {};
let requestIDCounter = 0;

// Ensure that the response from the latest request is used:
// If two identical requests are made in the order A then B, and A returns slower than B does, A will be killed and the results from B will be used.
// If a returns before B, the results from A will be used until B returns a result

async function killDuplicateRequests(activeRequest: {
  body: any;
  method: string;
  requestID: number;
  url: string;
  requestTimeStamp: Date;
}) {
  let requestIDs: any[] = [];

  if (activeRequests[activeRequest?.url]) {
    requestIDs = Object.keys(activeRequests[activeRequest.url]);
  }

  requestIDs.forEach(async requestID => {
    const req = activeRequests[activeRequest.url][requestID];
    if (req.requestID === activeRequest.requestID) {
      // Remove request from active requests
      delete activeRequests[activeRequest.url][requestID];
    } else if (
      activeRequest.url === req.url &&
      activeRequest.method === req.method &&
      JSON.stringify(activeRequest.body) === JSON.stringify(req.body)
    ) {
      // // kill all other requests with the same parameters & remove from active requests
      if (activeRequest.requestTimeStamp < req.requestTimeStamp) {
        // Abort only if request was made before the active request and has not yet returned a response
        await req.abortController.abort();
        console.warn("Aborted duplicate request", `${req.method}: ${req.url}`);
        delete activeRequests[activeRequest.url][requestID];
      }
    }
    if (Object.keys(activeRequests[activeRequest.url]).length === 0) {
      delete activeRequests[activeRequest.url];
    }
  });
}

// Signed request call an API Gateway endpoint and signs the request signature version 4 with the given credentials
async function signedRequest(
  store: any,
  endpoint: string,
  method: string,
  args?: any,
  headers?: any,
  shouldIgnore401?: boolean,
  retryCounter?: number,
  returnResponse?: boolean
): Promise<any> {
  const maxRetries = 3;
  const retryCounterValue: number = retryCounter ?? 0;

  if (!args) {
    args = {};
  }
  const accessToken = authUtils.getAccessToken(); // For jwt users

  const v1Endpoints = ["/login", "/time"];

  if (accessToken && v1Endpoints.indexOf(endpoint) === -1 && endpoint.indexOf("v2") === -1) {
    endpoint = `/v2${endpoint}`;
  }

  if (!headers) {
    headers = { "Content-Type": "application/json" };
  }

  headers["client-version"] = "web-" + packageJson.version;

  method = method.toUpperCase();

  try {
    let body = null;
    let path = endpoint;

    // Build query arguments from args for get
    if (method === "GET") {
      if (store && store.impersonated_user_id) {
        args.request_id = store.impersonated_user_id;
      }

      path += generalUtils.serialize(args);
      // Else use the args for the request body
    } else {
      body = JSON.stringify(args);

      if (store && store.impersonated_user_id) {
        path += generalUtils.serialize({
          request_id: store.impersonated_user_id
        });
      }
    }

    let url = `https://${config.api}${path}`;
    if (config.api.indexOf("localhost") >= 0) {
      url = `http://${config.api}${path}`;
    }

    // Options for the signature version 4 signing
    const opts: any = {
      method: method,
      path: path,
      headers: headers,
      hostname: config.api,
      url: url,
      region: config.region,
      service: "execute-api"
    };

    if (body !== null) {
      opts.body = body;
    }

    if (accessToken && v1Endpoints.indexOf(endpoint) === -1) {
      headers["Authorization"] = "bearer " + accessToken;
    }

    let request: any;
    const signedRequestObject = { headers, url };

    const init: { method: string; headers: any; body: any; signal?: any } = {
      method: method,
      headers: signedRequestObject.headers,
      body: body
    };

    if (method === "GET") {
      const requestID = requestIDCounter;
      requestIDCounter++;
      const abortController = new AbortController();
      request = {
        url: signedRequestObject.url,
        method,
        abortController,
        body,
        requestID,
        requestTimeStamp: new Date()
      };
      if (!activeRequests[url]) {
        activeRequests[url] = {};
      }
      activeRequests[url][requestID] = request;
      init.signal = abortController.signal;
    }

    const response = await fetch(signedRequestObject.url, init);

    if (method === "GET") {
      killDuplicateRequests(request);
    }

    if (!response.ok) {
      !shouldIgnore401 && checkTokenValidity(store, response, endpoint);
      generalUtils.checkMaintenanceMode(store, response);
      generalUtils.checkTokenExpired(store, response);
      generalUtils.checkAccountClosed(store, response);

      // Error message is in response body as text
      const errorMsg = await response.text();

      if (errorMsg.toLowerCase().indexOf("service unavailable") > -1) {
        if (retryCounterValue < maxRetries) {
          return new Promise(async resolve => {
            setTimeout(async () => {
              const res = await signedRequest(
                store,
                endpoint,
                method,
                args,
                headers,
                shouldIgnore401,
                retryCounterValue + 1
              );
              resolve(res);
            }, 1000 * (retryCounterValue + 1)); // Progressively longer timeouts as retries progress
          });
        } else {
          console.log("Service unavailable, maximum retries reached.");
        }
      }

      // Don't change this method without changing the backend
      if (errorMsg.indexOf("You're not logged in. Please log in and try again.") !== -1) {
        // Redirect back to login page if not logged in
        generalUtils.redirectToLoginPage();
        return;
      }

      return { ok: false, error: new Error(errorMsg), code: response.status };
    }
    if (!returnResponse) {
      let data = "";
      try {
        data = await response.text();
        data = JSON.parse(data);
      } catch (e) {
        // Assume it is text
      }

      return { ok: true, data, code: response.status, response };
    } else {
      return { ok: true, response, code: response.status };
    }
  } catch (e) {
    return { ok: false, error: generalUtils.getError(e, true) };
  }
}

function checkTokenValidity(store: any, response: any, endpoint: string) {
  if (response.status === 401 && endpoint !== "/login") {
    authUtils.logOut(store);
  }
}

async function getGeneralData(store: any, shouldIgnore401: boolean): Promise<any> {
  let error = null;
  let data: IObjectKeys = {};

  try {
    const result: IResult = await signedRequest(
      store,
      "/general-data",
      "GET",
      undefined,
      undefined,
      shouldIgnore401
    );

    if (result.ok) {
      data = result.data;
    } else {
      stopImpersonating(store);
      error = "Could not load general data.";
    }
  } catch (e) {
    error = "Could not load general data.";
    stopImpersonating(store);
  }

  if (data["account_settings"]) {
    const settings: any = {};

    for (const s of data["account_settings"]) {
      try {
        settings[s.setting] = JSON.parse(s.value);
      } catch (_) {
        // Not json
        settings[s.setting] = s.value;
      }
    }
    data["account_settings"] = settings;
  }

  return { data, error };
}

async function getSystemConfig(store: any): Promise<any> {
  const systemConfig: any = {};
  let error = null;

  try {
    const result: IResult = await signedRequest(store, "/config", "GET", {}, {});

    if (result.ok) {
      if (result.data.config) {
        for (const configObject of result.data.config) {
          try {
            configObject.value = JSON.parse(configObject.value);
          } catch (_) {
            // Do nothing
          }
          systemConfig[configObject.key] = configObject;
        }
      }
    } else {
      error = generalUtils.getError(result, null);
    }
  } catch (e) {
    error = generalUtils.getError(e, null);
  }

  return { systemConfig, error };
}

async function getUserSettings(store: any, settingName: string | undefined): Promise<any> {
  const activeUser = activeUserFromStore(store);
  if (!activeUser) return;
  let error = null;
  const queryParams: { limit: number; username: string; setting?: string } = {
    limit: 9999,
    username: activeUser.username
  };
  const settings: any = {};

  if (settingName) {
    queryParams["setting"] = settingName;
  }

  try {
    const result: IResult = await signedRequest(store, "/users/settings", "GET", queryParams, {});

    if (result.ok) {
      if (result.data.user_settings) {
        for (const s of result.data.user_settings) {
          try {
            settings[s.setting] = JSON.parse(s.value);
          } catch (_) {
            settings[s.setting] = s.value;
          }
        }
      }
    } else {
      console.log(result.error);
      error = "Could not load user settings.";
    }
  } catch (e) {
    console.log(e);
    error = "Could not load user settings.";
  }

  return { settings, error };
}

async function setUserSetting(store: IStore, key: any, value: any): Promise<any> {
  const result: IResult = await signedRequest(
    store,
    "/users/settings",
    "POST",
    {
      setting: key,
      value: value
    },
    null
  );

  if (result.ok) {
    store.user_settings[key] = value;
    return undefined;
  } else {
    return generalUtils.getError(result, null);
  }
}

async function setPushNotificationToken(store: any, username: string, token: string): Promise<any> {
  console.log("Setting push notification token", token);

  const result: IResult = await signedRequest(
    store,
    "/push-notifications/tokens",
    "POST",
    {
      project_slug: "bobapi",
      platform: "web",
      username,
      token
    },
    null
  );

  if (result.ok) {
    return undefined;
  } else {
    return generalUtils.getError(result, null);
  }
}

function activeUser(props: { store: any }): IUser | undefined {
  const { store } = props;
  if (props && store) {
    return activeUserFromStore(store);
  }

  return undefined;
}

function activeUserFromStore(store: any): IUser {
  return store.logged_in_user;
}

function addFiltersToArgs(
  filters: any,
  args: any,
  wildCardedColumns?: string[],
  dontChangeDates?: boolean
) {
  if (!wildCardedColumns) wildCardedColumns = [];

  Object.keys(filters).forEach(key => {
    if (filters[key] && (!Array.isArray(filters[key]) || filters[key].length > 0)) {
      let val = filters[key];

      if (key === "start_date" || key === "date" || key === "from_invoice_date") {
        if (dontChangeDates) {
          val = dateUtils.pgFormatDate(moment(val));
        } else {
          val = dateUtils.pgFormatDate(moment(val).startOf("day"));
        }
      }

      if (key === "end_date" || key === "to_invoice_date") {
        if (dontChangeDates) {
          val = dateUtils.pgFormatDate(moment(val));
        } else {
          val = dateUtils.pgFormatDate(moment(val).endOf("day"));
        }
      }

      if (Array.isArray(filters[key])) {
        args[key] = JSON.stringify(val);
        // @ts-ignore
      } else if (wildCardedColumns.indexOf(key) === -1) {
        args[key] = val;
      } else {
        // Encode with % wildcard (postgres) at the begining and end of the argument
        // The encoding is need because args are put into the query URL
        args[key] = "%" + val + "%";
      }
    }
  });
}

function addSortingToArgs(args: any, tableState: any) {
  if (tableState.sorted) {
    if (tableState.sorted.length) {
      args.order_by = tableState.sorted[0].id;
      args.order = tableState.sorted[0].desc ? "DESC" : "ASC";
    }
  }
}

async function generateAndOpenS3Resource(
  store: any,
  url: string,
  args: any,
  method: string = "GET"
): Promise<any> {
  try {
    const response: IResult = await signedRequest(store, url, method, args, {
      "Content-Type": "application/json"
    });

    if (response.ok && response.data) {
      const data: {
        url?: string;
        filename?: string;
        bucket?: string;
        email?: string;
        queued?: boolean;
        message?: string;
      } = response.data;
      if (data.url) {
        window.open(data.url);
      } else if (data.queued) {
        store.emitter.emit("showAlert", {
          title: "File download",
          body: `We see that you are trying to download a large number of items. Let us rather send them to ${data.email} once the file has been generated.`,
          showOkButton: true,
          return: () => {}
        });
      }
    } else if (
      !response.ok &&
      response.error.message.indexOf("User does not have an email address") >= 0
    ) {
      store.emitter.emit("showAlert", {
        title: "File download",
        body: "Please associate an email address with your account before attempting to download a large number of items.",
        showOkButton: true,
        return: () => {}
      });
    } else {
      return generalUtils.getError(response, null);
    }
  } catch (e) {
    return generalUtils.getError(e, null);
  }
}

async function getAccount(store: any, accountID?: number, shouldIgnore401?: boolean): Promise<any> {
  if (!accountID) {
    const activeUser: IUser = activeUserFromStore(store);
    if (!activeUser || !activeUser.account_id) return {};

    accountID = activeUser.account_id;
  }

  try {
    const result: IResult = await signedRequest(
      store,
      "/accounts",
      "GET",
      {
        id: accountID
      },
      undefined,
      shouldIgnore401
    );

    if (result.ok && result.data.accounts && result.data.accounts.length > 0) {
      return { account: result.data.accounts[0] };
    } else {
      return { error: generalUtils.getError(result, null) };
    }
  } catch (e) {
    return { error: generalUtils.getError(e, null) };
  }
}

async function updateAccount(store: any, account: IAccount): Promise<any> {
  try {
    const result: IResult = await signedRequest(store, "/accounts", "PATCH", account, null);

    if (result.ok) {
      // Update local state
      if (store.account) {
        store.set("account", result.data);
      }
      return { account: result.data };
    } else {
      return { error: generalUtils.getError(result, null) };
    }
  } catch (e) {
    return { error: generalUtils.getError(e, null) };
  }
}

function isAccountUser(props: { store: any }) {
  const { store } = props;
  const { impersonated_user_id } = store;
  const activeUser = activeUserFromStore(store);

  return impersonated_user_id || (activeUser && activeUser.account_id) || !activeUser;
}

function isMaintenanceModePage() {
  return window.location.pathname === "/maintenance";
}

function isAccountClosedPage() {
  return window.location.pathname === "/account-closed";
}

export async function checkTime(store: any, message?: string): Promise<{ ok?: boolean; e?: any }> {
  try {
    const now = moment(new Date());
    const response = await signedRequest(store, "/time?t=" + now.unix(), "GET");
    if (response.ok && response.data) {
      const serverTime = response.data;

      const duration = moment.duration(moment(now).diff(serverTime));
      const minutes = duration.asMinutes();

      const serverTimezoneOffset = moment(serverTime).utcOffset("+0200").format("Z");
      const localTimezoneOffset = moment(now).format("Z");

      if (Math.abs(minutes) > 5) {
        store.emitter.emit("showAlert", {
          title: "Are you a time traveller?",
          body: (
            <div>
              <div className="flex justify-center">
                <FontAwesomeIcon icon="clock" className="text-red mb-8 mt-4 " size="4x" />
              </div>
              <div className="mb-4">
                {message ?? "Your time is out of sync with our server time"}.
              </div>

              <div className="mb-4">
                Our current server time is{" "}
                <span className="font-bold">
                  {moment(serverTime).utcOffset("+0200").format("YYYY-MM-DD HH:mm Z")}
                </span>
                .
                <br />
                Please set your date and time to{" "}
                <span className="font-bold text-primary inline-flex items-center">
                  {moment(serverTime).format("YYYY-MM-DD HH:mm Z")}
                  <InfoButton>
                    <div className="mb-4">
                      This is your local date and time that correspond to our server time. If this
                      time seems incorrect, please ensure that the correct timezone is set for your
                      device.
                    </div>
                    {serverTimezoneOffset !== localTimezoneOffset && (
                      <Message.Info>
                        <div className="inline-flex space-x-4 items-center">
                          <FontAwesomeIcon icon="info-circle" />
                          <div> Your device is set to a different timezone than our server. </div>
                        </div>
                      </Message.Info>
                    )}
                  </InfoButton>
                </span>
              </div>
              <div className="font-bold">
                Setting your device date and time to the correct value is important for the
                application to work correctly.
              </div>
            </div>
          ),
          showOkButton: true,
          okButtonText: "I have fixed my date and time",
          okButtonVariant: "danger",
          shouldShowCancelButton: false,
          return: () => {
            window.location.reload();
          }
        });
        return { ok: false };
      } else {
        return { ok: true };
      }
    } else {
      return { e: response.error };
    }
  } catch (e) {
    return { e };
  }
}

async function loadFromS3(store: any, fileName: string, folder: string, bucket: string) {
  const result = await signedRequest(store, "/s3-url/download", "GET", {
    file_name: fileName,
    folder,
    bucket
  });
  if (result.ok) {
    return result.data;
  } else {
    return { error: generalUtils.getError(result) };
  }
}

async function uploadToS3(
  store: any,
  file: { name: string },
  folder: string,
  contentType: string,
  bucket: string
) {
  const result = await signedRequest(store, "/s3-url/upload", "GET", {
    file_name: file.name,
    folder,
    bucket
  });
  if (result.ok) {
    const url = result.data.url;
    const fileName = result.data.file_name;
    try {
      const response = await axios.put(url, file, {
        headers: {
          "Content-Type": contentType
        }
      });
      if (response.status === 200) {
        return { url, file_name: fileName };
      } else {
        const error = generalUtils.getError(response);
        return { error };
      }
    } catch (e) {
      const error = generalUtils.getError(e);
      return { error };
    }
  }
  const error = generalUtils.getError(result);
  return { error };
}

function checkBrowserVersion(store: any) {
  try {
    const browser = detect();

    const browserDetails = { name: browser.name, version: browser.version };
    const activeUser = activeUserFromStore(store);
    const userSettings = store.get("user_settings");
    const ignoreVersion = activeUser
      ? userSettings.ignore_browser_outdated
      : localStorage.getItem("ignore_browser_outdated");

    if (
      generalUtils.isBrowserOutdated(browserDetails.name, browserDetails.version) &&
      !ignoreVersion
    ) {
      store.emitter.emit("showAlert", {
        title: "Your browser is out of date",
        body: (
          <div>
            <div className="flex flex-col space-y-4">
              <div>
                It seems like your browser is out of date. Some features might not work as expected.
              </div>
              <div>In order to have the best experience, please update your browser.</div>
            </div>
          </div>
        ),
        showCancelButton: true,
        showOkButton: true,
        okButtonText: "Don't remind me again",
        cancelButtonText: "Ok",
        okButtonVariant: "danger",
        return: (val: any) => {
          if (val) {
            if (activeUser) {
              setUserSetting(store, "ignore_browser_outdated", "true");
            } else {
              localStorage.setItem("ignore_browser_outdated", "true");
            }
          }
        }
      });
    }
  } catch (e) {
    console.log("checkBrowserVersion: ", e);
  }
}

function stopImpersonating(store: any) {
  if (store.impersonated_user_id) {
    store.set("impersonated_user_id", undefined);
    localStorage.removeItem("impersonated_user_id");
    if (store.account) {
      setTimeout(() => {
        window.location.href = `/accounts/${store.account.id}`;
      }, 200);
    } else {
      window.location.href = "/users";
    }
  }
}

// {a: "b"} => [{key: "a", value: "b"}]
function objectToArray(object: any): { key: string; value: any }[] {
  return Object.keys(object).map(key => {
    return { key, value: object[key] };
  });
}

// [{key: "a", value: "b"}] => {a: "b"}
function arrayToObject(array: { key: string; value: any }[]) {
  const obj: any = {};
  array.forEach(a => {
    obj[a.key] = a.value;
  });
  return obj;
}

export function getNotificationStatusColor(status: string): TailwindColors {
  if (status === "completed") {
    return "green";
  }
  if (status === "pending") {
    return "blue";
  }
  return "red";
}

export function getLocationStatusColor(status: string): TailwindColors {
  switch (status) {
    case "active":
      return "green";
    case "inactive":
      return "pink";
    case "prospect":
      return "yellow";
    case "draft":
      return "gray";
    default:
      return "blue";
  }
}

function getBobBoxLocationRangeColor(location: IBobBoxLocation) {
  if (location.status === "prospect") {
    return "#fbc024";
  } else if (location.status === "draft") {
    return "#374151";
  } else if (location.type === "bob-box-locker") {
    return "#1DBE4C";
  } else if (location.type === "bob-box-counter") {
    return "#007BFF";
  }
  return "#f4405e";
}

function getBobBoxLocationServiceRange(store: any, location: IBobBoxLocation): number {
  const rangeKm = location.service_range_km;

  if (!rangeKm) {
    return getDefaultBobBoxLocationServiceRange(store);
  }
  return rangeKm;
}

function getDefaultBobBoxLocationServiceRange(store: any): number {
  return parseFloat(
    store?.system_config.find((config: any) => config.key === "default_bob_box_service_range")
      ?.value
  );
}

export function getCompartmentStatusColor(status: string): TailwindColors {
  if (status === "available") {
    return "green";
  } else if (status === "reserved" || status === "occupied") {
    return "yellow";
  } else if (status === "expired") {
    return "red";
  } else if (status === "out-of-service") {
    return "pink";
  }

  return "gray";
}

export function getDoorStatusColor(status: string): TailwindColors {
  if (status === "open") {
    return "pink";
  } else if (status === "closed") {
    return "green";
  }
  return "gray";
}

export function getSecureCitizenStatusColor(status: string): TailwindColors {
  if (status === "complete") {
    return "green";
  } else if (status === "pending") {
    return "blue";
  }

  return "pink";
}

export function getReservationStatusColor(status: string): TailwindColors {
  if (status === "waiting-for-dropoff") {
    return "blue";
  } else if (status === "waiting-for-pickup") {
    return "green";
  } else if (status === "expired" || status === "cancelled") {
    return "pink";
  }

  return "gray";
}

function getLockerCommsMessageType(value: any) {
  return (
    constants.lockerCommsMessageTypes.find(
      lockerCommsMessageType => lockerCommsMessageType.value === value
    ) || {}
  ).label;
}

function getLockerCommsSourceType(value: any) {
  return (
    constants.lockerCommsSourceTypes.find(
      lockerCommsSourceType => lockerCommsSourceType.value === value
    ) || {}
  ).label;
}

function isProd() {
  return config.api === "api.bob.co.za";
}

function isZip(file: any): RegExpExecArray | null | false {
  if (!file) return false;

  const validationRegex = /(\.zip)$/i;
  return validationRegex.exec(file.name.toLowerCase());
}

function getEnvironment(url: string) {
  if (url.includes("dev")) {
    return "dev";
  }
  if (url.includes("sandbox")) {
    return "sandbox";
  }

  return "prod";
}

export function getNBiotColor(value?: string): TailwindColors {
  if (value === "GREAT") {
    return "green";
  } else if (value === "GOOD" || value === "FAIR") {
    return "yellow";
  } else if (value === "POOR") {
    return "pink";
  }

  return "gray";
}

function getRSRP(rsrpValue: number) {
  let rsrp = "";
  switch (true) {
    case isNaN(rsrpValue) || rsrpValue === 0:
      break;
    case rsrpValue >= -80:
      rsrp = "GREAT";
      break;
    case rsrpValue >= -90:
      rsrp = "GOOD";
      break;
    case rsrpValue >= -100:
      rsrp = "FAIR";
      break;
    default:
      rsrp = "POOR";
  }
  return rsrp;
}

function getRSRQ(rsrqValue: number) {
  let rsrq = "";
  switch (true) {
    case isNaN(rsrqValue) || rsrqValue === 0:
      break;
    case rsrqValue >= -10:
      rsrq = "GREAT";
      break;
    case rsrqValue >= -15:
      rsrq = "GOOD";
      break;
    case rsrqValue >= -20:
      rsrq = "FAIR";
      break;
    default:
      rsrq = "POOR";
  }
  return rsrq;
}

function getSINR(sinrValue: number) {
  let sinr = "";
  switch (true) {
    case isNaN(sinrValue) || sinrValue === 0:
      break;
    case sinrValue >= 20:
      sinr = "GREAT";
      break;
    case sinrValue >= 13:
      sinr = "GOOD";
      break;
    case sinrValue >= 0:
      sinr = "FAIR";
      break;
    default:
      sinr = "POOR";
  }
  return sinr;
}
function isBobBoxCounterDomain() {
  return window.location.hostname.indexOf("counter") >= 0;
}

function isBobBoxManageDomain() {
  return window.location.hostname.indexOf("manage") >= 0;
}

function isBobBoxDomain() {
  return isBobBoxCounterDomain() || isBobBoxManageDomain();
}

function hasBobBoxTheme(activeUser?: IUser) {
  return (activeUser && isBobBoxUser(activeUser)) || isBobBoxDomain();
}

function getLocationName(location: IBobBoxLocation) {
  const humanName = location?.human_name;
  const name = location?.name;

  return humanName ? (name ? `${humanName} – ${name}` : humanName) : name;
}

function humanReadableUserType(userType: string) {
  if (userType === "jwt") {
    return userType.toUpperCase();
  } else if (userType === "api-key") {
    return "API Key";
  } else {
    return generalUtils.keyToHumanReadable(userType);
  }
}

function getTimeModified(time_created: Date, time_modified: Date): string | null {
  if (time_modified && time_created !== time_modified) {
    return moment(time_modified).fromNow();
  }
  return null;
}

export {
  isBobBoxManageDomain,
  isBobBoxCounterDomain,
  hasBobBoxTheme,
  getBobBoxLocationServiceRange,
  getDefaultBobBoxLocationServiceRange,
  getBobBoxLocationRangeColor,
  loadFromS3,
  uploadToS3,
  isMaintenanceModePage,
  isAccountClosedPage,
  updateAccount,
  getAccount,
  generateAndOpenS3Resource,
  addFiltersToArgs,
  addSortingToArgs,
  activeUser,
  activeUserFromStore,
  getGeneralData,
  signedRequest,
  getSystemConfig,
  getUserSettings,
  setUserSetting,
  isAccountUser,
  checkBrowserVersion,
  stopImpersonating,
  setPushNotificationToken,
  objectToArray,
  arrayToObject,
  getLockerCommsMessageType,
  getLockerCommsSourceType,
  isProd,
  isZip,
  getEnvironment,
  getRSRP,
  getRSRQ,
  getSINR,
  getLocationName,
  humanReadableUserType,
  getTimeModified
};
